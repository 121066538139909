<template>
    <div id="frame-box">
        <div id="left-menu">
            <ul id="left-menu-ul">
                <li v-for="(item, index) in bookMark"
                    v-bind:key="index"
                    :class="{selected:bookMarkSelected === item}"
                    @click="menuSelect(item)">
                    <a>{{ item.typeName }}</a></li>
            </ul>
        </div>
        <div id="right-main" v-if="bookMarkSelected.data">
            <span v-for="(item, index) in bookMarkSelected.data"
                  v-bind:key="index">
                <a :href="item.clickUrl" target="_blank">{{ item.remarks }}</a>
            </span>
        </div>
    </div>
</template>

<script>
import storage from '@/utils/storage'

export default {
    name: 'navigate',
    data() {
        return {
            storageKey: 'key-cache-data-software-url-v01',
            bookMark: [],
            bookMarkSelected: '',
        }
    },
    methods: {
        menuSelect(val) {
            this.bookMarkSelected = val
        },
        getSoftWareType() {
            let data = storage.get(this.storageKey) || []
            if (data && data.length) {
                this.bookMark = data
                this.bookMarkSelected = this.bookMark[0] || []
            } else {
                C.Api.getBookType(5).then((response) => {
                    let bookType = response
                    let idListStr = []
                    for (let i = 0; i < bookType.length; i++) {
                        idListStr.unshift(bookType[i].id)
                    }
                    idListStr = idListStr.join(',')
                    this.getSoftWareUrl(idListStr)
                })
            }

        },
        getSoftWareUrl(idListStr) {
            C.Api.getBookMark(idListStr).then((response) => {
                this.bookMark = response
                if (this.bookMark && this.bookMark.length) {
                    this.bookMarkSelected = this.bookMark[0] || []
                }
                storage.set(this.storageKey, this.bookMark)
            })
        },
    },
    created() {
        this.getSoftWareType()
    },
}
</script>

<style scoped>

#frame-box {
    height: 85%;
    width: 85%;
    margin: 0 auto;
    padding: 57px 0 0 0;
    min-width: 640px;
}

#left-menu {
    height: auto;
    margin: 28px 0;
    float: left;
}

#left-menu ul li {
    text-align: center;
    list-style: none;
    height: 40px;
    padding: 0;
    margin: 6px 0;
}

#left-menu ul li a {
    vertical-align: middle;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    margin: 4px 0;
}


#right-main {
    font-size: 14px;
    padding: 0 0 0 10px;
    margin: 28px 0 0 0;
    height: 100%;
    width: auto;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: normal;
    word-break: break-all;
}

#right-main span {
    height: 40px;
    padding: 0;
    margin: 6px 0;
    font-size: 16px;
}

#right-main span a {
    height: 44px;
    line-height: 44px;
    padding: 0 16px;
    margin: 4px 0;
}

</style>